$blue: #3c8cb9; //peter:3296dc,belize:2882b9,picton:55aad2,denim:2869af
$silver: #bec3c8;
$whale: #283c50;
$persia: #00aa87;
$emerald: #008064;
$amethyst: #9164b9;
$cloud: #ebf0f0;
$iron: #96a5a5;
$turbo: #fac71e;
$sun: #f57832;
$orange: #f0c30f;
$alizarin: #e64b3c;
$cinnabar: #e14b37;
$valencia: #d24641;
$red: #c3372d;
$pomegranate: #be372d;
$wales: #b93232;
