@use "responsive" as *;

div.social {
  margin: 8px 8px 8px 24px; padding: 2px;
  a.icon {
    display: inline-block; width: 24px; height: 24px; margin: 0 2px;
    vertical-align: -8px;
    @media #{$standard} {width: 24px; height: 24px; vertical-align: -8px;}
    &.youtube {background: {image: url(/assets/img/youtube.png); size: 100% 100%}}
    &.instagram {background: {image: url(/assets/img/insta.png); size: 100% 100%}}
    &.fb {background: {image: url(/assets/img/fb.png); size: 100% 100%}}
    &.vk {background: {image: url(/assets/img/vk.png); size: 100% 100%}}
    &.tw {background: {image: url(/assets/img/tw.png); size: 100% 100%}}
  }
}
