@use "colors" as *;
@use "responsive" as *;
@use "social" as *;

#home {
  div.social{
      margin: 12px auto 0px auto;
      text-align: center;
      @media #{$standard} {
        font-size: 12pt;
      }
      @media #{$tablet} {
        font-size: 12pt;
      }
  }
  @media #{$standard} {
    padding-left: 168px;
  } // @media $standard
  div.pagination {
    margin: 8px;
    text-align: center;
    font-size: 12pt;
    color: $whale;
    div.menu {
      border-top: 1px $whale solid;
      border-bottom: 1px $whale solid;
      display: inline-block;
      padding: 2px 8px;
      @media #{$mobile}{display: block; padding: 2px;}
      span, a {
        display: inline-block;
        padding: 2px 4px;
        &:first-child {border: {top-left-radius: 2px; bottom-left-radius: 2px}}
        &:last-child {border: {top-right-radius: 2px; bottom-right-radius: 2px}}
      }
      span {
        color: white; background: $whale;
        border-left: solid 1px $whale;
      }
      a {
        color: $whale; background: $cloud;
        text-decoration: none;
        border-left: solid 1px $silver;
      }
      a:first-child, span+a {border-left-color: $cloud}
    }

    @media #{$tablet}{font-size: 14pt}
  } // menu.pagination
  article {
    clear: both;
    margin: 16px 0;
    aside {
      display: block; color: $persia;
      font: {size: 12pt; weight: 600;}
      text-align: center;
      margin: 0 8px; padding: 2px 4px 4px 4px;
      box-shadow: 0px 0px 8px $iron;
      background-image: radial-gradient(at 10px 10px, white, $cloud);
      border-top: {right-radius: 4px; left-radius: 4px;}
      address {display: inline; color: $whale; font-style: italic;}
      div {
        display: inline-block; margin: 2px 6px;
        text-align: center;
        a.share {vertical-align: -25%}
      }
      img {display: none;}
      @media #{$standard} {
        float: left; width: 160px;
        margin: {left: -160px; right: 0}
        text-align: left; padding: 0;
        box-shadow: none; border-radius: none; background: none;
        address {display: block;}
        div {
          display: block; margin: 8px auto;
          width: 130px;
        }
        img {
          display: block;
          margin: 8px auto;
          max-width: 140px;
          border: none;
          border-radius: 2px;
          box-shadow: none;
        }
      }
    } // aside

    .no-excerpt {display: none !important}
    .mobile-excerpt {
      display: none !important;
      @media #{$mobile}{display: block !important}
    }

    .title {
      display: block; text-decoration: none;
      font: {size: 11pt; weight: 700;}
      text-align: center;
      color: white;
      background-image: radial-gradient(at 10px 10px, $persia, $emerald);
      padding: 8px;
      box-shadow: 0 0 2px black;
      @media #{$tablet} {font-size: 12pt}
      @media #{$standard} {
        font-size: 14pt;
        text-align: right;
        margin-top: 16px;
        padding: 4px 8px;
        border-radius: 8px;
      }
    } // .title

    section {
      overflow: auto;
      margin: 0 8px; padding: 2px 4px 4px 4px;
      background: $cloud;
      box-shadow: 0px 0px 8px $iron;
      border-bottom: {right-radius: 4px; left-radius: 4px;}
      @media #{$standard} {
        margin: 0; padding: 0; background: none; box-shadow: none; border: none;
        font-size: 12pt;
      }
    }

    h1 {
      margin-top: 8px;
      color: black;
      font-size: 11pt;
      @media #{$standard} {font-size: 14pt}
    }
    p, ul {color: $whale}
    mark {background: none; color: $amethyst; font-weight: 600;}
    strong {background: none; color: black; font-weight: 600;}
    p {
      text-align: justify;
      hyphens: auto; -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto;
    }

    .float-left, .float-right {
      margin: 8px auto;
      display: block;
    }
    @media #{$standard} {
      .float-left {
        float: left;
        margin: 8px;
        max-width: 160px;
      }
      .float-right {
        float: right;
        margin: 8px;
      }
      .block-center {
        display: block; width: 100%;
        text-align: center; text-indent: 0;
        margin: {left: auto; right: auto;}
        img {display: inline-block;}
      }
    }

  } // article
}
